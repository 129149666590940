.register-page {
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.register-page .register-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
  padding: 60px 80px;
}

.register-page .register-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.register-page .register-header img {
  width: 100px;
  height: 100px;
}

.register-page .register-header h2 {
  color: #333;
  margin: 0;
}

.register-page .auth-info {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.register-page .auth-info input,
.register-page .auth-info .select-organizaton,
.register-page .auth-info .select-group {
  all: unset;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  padding-right: 35px;
}

.register-page .auth-info .select-organizaton,
.register-page .auth-info .select-group {
  cursor: pointer;
}

.register-page .auth-info .select-group {
  margin-bottom: 20px;
}

.register-page .register-content p {
  color: red;
  text-align: center;
  margin: 0;
}

.register-page .password,
.register-page .confirm-password {
  position: relative;
}

.register-page .password button,
.register-page .confirm-password button {
  all: unset;
  cursor: pointer;
}

.register-page .password i,
.register-page .confirm-password i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  text-align: center;
}

.register-page .register-button {
  all: unset;
  cursor: pointer;
  background-color: var(--themeColor);
  width: 400px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-top: 20px;
}

.register-page .register-button h3 {
  color: white;
  font-size: 16px;
  margin: 0;
}
