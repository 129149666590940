.display-reservations {
  display: flex;
  justify-content: space-between;
  min-height: 90vh;
}

.display-now {
  width: 60%;
  padding: 1%;
  border: 2px solid #f0f0f0;
  border-radius: 12px;
}

.display-upcoming {
  width: 32%;
  padding: 1%;
  border: 2px solid #f0f0f0;
  border-radius: 12px;
}

.display-escape-arrow {
  all: unset;
  cursor: pointer;
  display: inline-flex;
}

.display-escape-arrow h1 {
  margin: 0px;
}
