.reserve-block {
  color: white;
  white-space: nowrap;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  margin-left: 20px;
}

.reserve-block-info:hover {
  opacity: 0.9;
  cursor: grab;
}

.reserve-block-snap-back {
  transform: translate(0, 0);
}

.reserve-block-info {
  background-color: var(--themeColor);
  padding: 10px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reserve-block-info h3 {
  color: white;
}

.reserve-block-info > * {
  margin: 5px;
}

.reserve-block-buttons {
  background-color: var(--themeDarkColor);
  padding: 10px 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20%;
}

.reserve-block-buttons button {
  all: unset;
  cursor: pointer;
}

.reserve-block-buttons h3:hover {
  color: var(--themeColor);
}

.reserve-block-buttons h3 {
  color: white;
  margin: 0;
}
