.reservations-itemized {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.reservations-itemized table {
  width: auto;
  border-collapse: collapse;
}

.reservations-itemized .table-section {
  margin-bottom: 20px;
}

.reservations-itemized th,
.reservations-itemized td {
  padding: 10px;
  text-align: left;
}

.reservations-itemized tr {
  border-bottom: 2px solid #ddd;
}

.reservations-itemized tr:last-child {
  border-bottom: none;
}
