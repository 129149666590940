.large-caption {
  font-weight: bold;
  font-size: 20px;
  color: black;
  padding: 0px 0px 4px;
}

.caption {
  font-weight: bold;
  font-size: 16px;
  padding: 0px 0px 16px;
}

.pics img {
  width: 256px;
  height: 384px;
  object-fit: cover;
  padding: 8px 0px;
}

.pics {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 200px;
  background-color: white;
  gap: 64px;
}
