.sidebar {
  position: fixed;
  width: max(10vw, 200px);
  height: 100vh;
  background-color: #333;
  color: white;
  transition: left 0.3s ease;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.sidebar .logo img {
  margin-bottom: 30px;
}

.sidebar h3 {
  margin: 0;
}

.sidebar ul {
  list-style: none;
  padding: 10px 0;
}

.sidebar li {
  margin: 20px 0;
}

.sidebar a {
  color: white;
  text-decoration: none;
}

.menu-divider {
  width: 90%;
  background-color: white;
  height: 1px;
}

.sidebar .active-link {
  color: var(--themeColor);
}

.logout-container {
  margin-top: auto;
  padding: 5vh 0;
}

.logout-button {
  background-color: var(--themeColor);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: var(--themeDarkColor);
}
