.requests-page {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.requests-page .requests-header {
  padding: 0 20px;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.requests-page .requests-header h3 {
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 1;
}

.requests-page .requests-header button {
  all: unset;
  cursor: pointer;
  color: var(--themeColor);
}

.requests-page .filters-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.requests-page .filters-container button {
  all: unset;
  cursor: pointer;
}

.requests-page .filters-container button.active {
  color: var(--themeColor);
}

.requests-page .requests-page-columns {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 85vh;
}

.requests-page .requests-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 25vw;
  align-items: center;
  overflow-y: scroll;
}

.requests-page .request-card {
  border-radius: 20px;
  align-items: center;
  width: 100%;
}

.requests-page .request-card.Athletics {
  background-color: var(--athleticOrange);
}

.requests-page .request-card.Clubs {
  background-color: var(--clubBlue);
}

.requests-page .request-card.Renters {
  background-color: var(--renterPurple);
}

.requests-page .request-card.Other {
  background-color: var(--othersGray);
}

.requests-page .request-card.Conflict {
  background-color: var(--rejectRed);
}

.requests-page .request-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  align-items: flex-start;
}

.requests-page .request-info-container:hover {
  cursor: pointer;
  opacity: 0.75;
}

.requests-page .request-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  gap: 20px;
}

.requests-page .request-details {
  width: 45%;
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 20px;
}

.requests-page .request-card h4 {
  color: black;
  margin: 0;
}

.requests-page .request-card.Athletics.selected {
  border-radius: 20px;
  border: 4px solid var(--athleticDarkOrange);
}

.requests-page .request-card.Clubs.selected {
  border-radius: 20px;
  border: 4px solid var(--clubDarkBlue);
}

.requests-page .request-card.Renters.selected {
  border-radius: 20px;
  border: 4px solid var(--renterDarkPurple);
}

.requests-page .request-card.Other.selected {
  border-radius: 20px;
  border: 4px solid var(--othersDarkGray);
}

.requests-page .middle-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 20vw;
}

.requests-page .details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
  padding: 20px;
  align-items: flex-start;
  overflow-y: scroll;
  min-height: 20%;
}

.requests-page .details-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}

.requests-page .details-header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}

.requests-page .details-info h3 {
  margin: 0;
}

.requests-page .details-attributes h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 15px 0;
}

.requests-page .details-header button {
  all: unset;
  cursor: pointer;
}

.requests-page .details-header button:hover {
  opacity: 0.75;
}

.requests-page .comment-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.requests-page .comment-box button {
  all: unset;
  cursor: pointer;
  color: var(--themeColor);
}

.requests-page .comment-box textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  resize: none;
  margin: 0;
}

.requests-page .conflicts-container {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
  padding: 20px;
  align-items: flex-start;
  height: 60%;
  overflow-y: scroll;
  gap: 20px;
  flex-grow: 1;
}

.requests-page .calendar-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
  padding: 20px;
  align-items: flex-start;
  flex-grow: 1;
}

.requests-page .calendar-container-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.requests-page .requests-calendar {
  width: 100%;
  height: 90%;
}

.requests-page .fc-toolbar-title {
  font-size: 1.17em;
  font-weight: bold;
}

.requests-page .fc-col-header-cell {
  pointer-events: none;
  cursor: default;
}

.requests-page .calendar-container-content h4 {
  margin: 10px 0;
}

.requests-page .review-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
}

.requests-page .review-buttons button {
  all: unset;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px;
}

.requests-page .review-buttons button:hover {
  opacity: 0.75;
}

.requests-page .review-buttons .reject {
  color: var(--buttonDarkRed);
  border: 1px solid var(--buttonDarkRed);
}

.requests-page .review-buttons .accept {
  color: var(--buttonDarkestGreen);
  border: 1px solid var(--buttonDarkestGreen);
}

.requests-page .no-selected-request {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
