.reservation-card {
  width: 15%;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  text-align: left;
  margin-bottom: 20px;
}

.reservation-card button {
  all: unset;
  width: 100%;
  cursor: pointer;
  text-align: left;
  background: none;
  color: inherit;
}

.reservation-card h2 {
  color: black;
}

.reservation-card h3 {
  color: black;
}

.reservation-card .truncated-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reservation-card.Approved {
  background-color: var(--confirmGreen);
}

.reservation-card.Pending {
  background-color: var(--pendingYellow);
}

.reservation-card.Rejected {
  background-color: var(--rejectRed);
}
