.reserve-block-detail-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.reserve-block-detail {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  min-width: 20vw;
  max-width: 50vw;
  position: relative;
  overflow-y: scroll;
}

.reserve-block-detail h3 {
  color: black;
}

.reserve-block-detail .close-button {
  font-size: 24px;
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  float: right;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
}

.reserve-block-detail-comments {
  padding: 10px 20px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
}
