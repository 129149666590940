.location-select-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.location-select {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 30vw;
  height: 70vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.location-select .close-button {
  font-size: 24px;
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  float: right;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
}

.location-select .location-search {
  position: relative;
  margin-bottom: 2%;
}

.location-select .location-search i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.location-select .location-search input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  padding-right: 35px;
}

.location-select .location-list {
  height: 100%;
  overflow-y: scroll;
  padding: 20px 20px 0 20px;
  border-radius: 12px;
  border: 2px solid #f0f0f0;
}

.location-select .location-card,
.location-select .location-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 12px;
  margin-bottom: 8px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  width: 100%;
  align-items: center;
  padding: 30px 30px;
  width: calc(100% - (var(--depth) * 20px));
  margin-left: calc(var(--depth) * 20px);
}

.location-select .location-card.selected {
  background-color: var(--themeLightColor);
}

.location-select .location-card h3,
.location-select .location-category h3 {
  cursor: pointer;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0px;
}

.location-select .location-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-20px);
}
