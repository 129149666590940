.reserve-create {
  border-radius: 12px;
  padding: 30px;
  width: 100%;
  max-width: 40%;
  border: 2px solid #f0f0f0;
  min-height: 85vh;
}

.reserve-create .reserve-activity,
.reserve-create .reserve-organization-select,
.reserve-create .reserve-selected-location,
.reserve-create .reserve-event-name,
.reserve-create .reserve-short-fields,
.reserve-create .reserve-materials-needed,
.reserve-create .reserve-comments,
.reserve-create .reserve-estimated-costs {
  margin-bottom: 5%;
}

.reserve-create h2 {
  text-align: center;
  margin-bottom: 5%;
  margin-top: 0;
}

.reserve-create label {
  display: flex;
  margin-bottom: 1%;
  font-weight: bold;
}

.reserve-create .reserve-short-fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reserve-create .reserve-start-time,
.reserve-create .reserve-end-time,
.reserve-create .reserve-party-size {
  width: 30%;
}

.reserve-create select,
.reserve-create input[type="text"],
.reserve-create input[type="number"],
.reserve-create input[type="date"],
.reserve-create input[type="time"],
.reserve-create textarea,
.reserve-create .reserve-selected-location button,
.reserve-create .reserve-organization-select button {
  all: unset;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 0px;
}

.reserve-create select:disabled,
.reserve-create input[type="text"]:disabled,
.reserve-create input[type="number"]:disabled,
.reserve-create input[type="date"]:disabled,
.reserve-create input[type="time"]:disabled,
.reserve-create textarea:disabled,
.reserve-create .reserve-selected-location button:disabled {
  background-color: #ccc;
}

.reserve-create .reserve-submit button:disabled,
.reserve-create .reserve-submit button:disabled,
.reserve-create .reserve-submit button:hover:disabled {
  color: gray;
  border: none;
  background-color: #ccc;
}

.reserve-create select {
  appearance: none;
  cursor: pointer;
}

.reserve-create .reserve-selected-location button,
.reserve-create .reserve-organization-select button {
  cursor: pointer;
}

.reserve-create textarea {
  resize: vertical;
  height: 10%;
}

.reserve-create .error-message {
  margin-bottom: 10px;
  color: var(--themeColor);
}

.reserve-create .reserve-submit {
  float: right;
}

.reserve-create .reserve-submit button {
  all: unset;
  border: 1px solid var(--themeColor);
  border-radius: 8px;
  padding: 10px;
  font: inherit;
  color: var(--themeColor);
}

.reserve-create .reserve-submit button:hover {
  cursor: pointer;
  color: var(--themeDarkColor);
  border: 1px solid var(--themeDarkColor);
}

.reserve-create .reserve-materials-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1%;
}

.reserve-create .reserve-materials-header label {
  margin: 0;
}

.reserve-create .reserve-materials-header button {
  all: unset;
  cursor: pointer;
  color: var(--themeColor);
}

.reserve-create .reserve-materials-header button:hover {
  color: var(--themeDarkColor);
}

.reserve-create .reserve-materials-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.reserve-create .reserve-materials-row .empty-materials {
  border: 1px solid gray;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  color: gray;
}
