.reservation-display {
  width: 95%;
  padding: 5px 2%;
  border: 2px solid #f0f0f0;
  border-radius: 12px;
  margin-bottom: 1%;
  background-color: white;
}

.reservation-display .horizontal-align {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  gap: 5%;
  align-items: flex-start;
}

.reservation-display .horizontal-align h3 {
  margin-bottom: 0px;
}

.reservation-display .display-time {
  white-space: nowrap;
}

.reservation-display .display-location {
  color: black;
}
