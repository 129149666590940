.notifications-bar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.notifications-bar {
  position: fixed;
  background-color: white;
  top: 0;
  right: 0;
  height: 100vh;
  width: 25%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: scroll;
}

.notifications-bar .close-button {
  font-size: 24px;
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  float: right;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
}

.notifications-bar .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.notifications-bar .notification-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  width: 100%;
}

.notifications-bar .empty-notifications {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
