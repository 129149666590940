.reserve-page {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: stretch;
}

.reserve-page h2 {
  margin-top: 0;
}

.reserve-page .reserve-build {
  display: flex;
  flex-direction: column;
  width: 55%;
  min-height: 85vh;
  justify-content: space-between;
  gap: 20px;
}

.reserve-page .reserve-build-header {
  display: flex;
  border-radius: 12px;
  border: 2px solid #f0f0f0;
  justify-content: space-between;
  padding: 10px 10px;
  justify-content: space-between;
}

.reserve-page .reserve-build-header .title {
  text-align: center;
  flex-grow: 1;
}

.reserve-page .reserve-build-header h2 {
  margin: 0;
}

.reserve-page .reserve-build-header button {
  all: unset;
  cursor: pointer;
  display: flex;
  margin: 0px;
  background: none;
  border: none;
}

.reserve-page .reserve-blocks {
  display: flex;
  border-radius: 12px;
  border: 2px solid #f0f0f0;
  height: auto;
  padding: 20px 0;
  flex-direction: column;
}

.reserve-page .reserve-blocks-row {
  overflow-y: scroll;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  z-index: 999;
}

.reserve-page .reserve-block-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reserve-page .reserve-final-button {
  all: unset;
  height: 20px;
  background-color: var(--themeColor);
  border-radius: 12px;
  padding: 10px;
  font: inherit;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.reserve-page .reserve-final-button:hover {
  cursor: pointer;
  background-color: var(--themeDarkColor);
}

.reserve-page .reserve-final-button h4 {
  margin: 0;
}

.reserve-page .reserve-final-button:disabled {
  background-color: #ccc;
  color: gray;
}

.reserve-page .reserve-calendar {
  display: flex;
  border-radius: 12px;
  padding: 30px;
  border: 2px solid #f0f0f0;
  height: 60%;
  flex-grow: 1;
}

.reserve-page .reserve-calendar .reserve-event {
  background-color: var(--themeColor);
}

.reserve-page .reserve-calendar .reserve-event:hover {
  color: var(--themeLightColor);
}

.reserve-page .reserve-calendar .reserve-event.Others {
  background-color: var(--othersDarkestGray) !important;
}
