/* Font Imports */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Roboto:wght@300;400&display=swap");

/* General Styles */
* {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: white;
  color: #666666;
}

h1,
h2,
h3 {
  color: var(--themeColor);
}

.unbold {
  font-weight: normal;
}

.logo {
  margin-right: auto;
}

.logo img {
  width: 150px;
  height: 50px;
  object-fit: cover;
}

body.no-scroll {
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  padding: 20px;
}

footer {
  background-color: #2b2d42;
  color: white;
  text-align: center;
  padding: 10px;
}

.center {
  text-align: center;
}

.text-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 16px 0px;
}

.safe-area {
  padding: 2vw 1vw 4vw min(15vw, 320px);
}

.safe-area-ignore-sidebar {
  padding: 2vw;
}
