.reservation-detail-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.reservation-detail {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  min-width: 20vw;
  max-width: 50vw;
  position: relative;
  overflow-y: scroll;
}

.reservation-detail h3 {
  color: black;
}

.reservation-detail .close-button {
  font-size: 24px;
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  float: right;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
}

.reservation-detail-comments {
  padding: 10px 20px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
}

.reservation-detail-button-container {
  margin-top: 10%;
  display: flex;
  justify-content: center;
  gap: 25%;
}

.reservation-detail-button-container h3 {
  color: black;
}

.reservation-detail-button-container button {
  width: 50%;
  padding: 10px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.reservation-detail-button-container button:hover {
  opacity: 75%;
  cursor: pointer;
}

.reservation-detail-button-container .reservation-detail-cancel {
  background-color: var(--rejectRed);
}

.reservation-detail-button-container .reservation-detail-edit {
  background-color: var(--editBlue);
}

.reservation-detail .dates-container {
  display: flex;
  flex-direction: column;
}
