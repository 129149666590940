.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  background-color: white;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.1);
  height: 100px;
}

.nav a {
  margin-right: 20px;
  text-decoration: none;
  color: #333;
}

.nav a.active {
  color: var(--themeColor);
}

.divider {
  width: 1px;
  background-color: #333;
  height: 30px;
  margin: 0 20px;
}

.auth-buttons .getstarted,
.auth-buttons .login {
  padding: 10px 20px;
  height: 40px;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.auth-buttons .getstarted {
  margin-right: 0;
}

.getstarted {
  background-color: var(--themeColor);
  color: white;
}

.getstarted:hover {
  background-color: #b61f27;
}

.login {
  background-color: transparent;
  color: var(--themeColor);
  border: 2px solid var(--themeColor);
}

.login:hover {
  background-color: #e0e0e0;
  color: black;
}
