.home {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.home .home-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.home .header-buttons {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.home .header-buttons button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid #f0f0f0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.home .header-buttons h1 {
  color: gray;
}

.home .home-columns {
  display: flex;
  flex-direction: row;
}

.home .reservation-cards-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  gap: 30px;
}

.home .reservation-column {
  padding: 2%;
  width: 100%;
  flex-shrink: 0;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
  box-sizing: border-box;
}

.home button {
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
}

.home .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home .show-all h2:hover {
  color: var(--themeDarkColor);
}

.home .empty-reservation-card {
  display: flex;
  width: 100%;
  padding: 20px;
  border: 2px dashed #f0f0f0;
  border-radius: 12px;
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
}
