.confirmation-popup-lite-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.confirmation-popup-lite {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  min-width: 20vw;
  max-width: 60vw;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: 70vh;
  align-items: center;
}

.confirmation-popup-lite h3 {
  color: black;
}

.confirmation-popup-lite h4 {
  margin: 0;
}

.confirmation-popup-lite .close-button {
  font-size: 24px;
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  float: right;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
}

.confirmation-popup-lite .child {
  overflow-y: scroll;
  overflow-x: scroll;
  border: 2px solid #f0f0f0;
  border-radius: 12px;
}

.confirmation-popup-lite-button-container {
  margin-top: 10%;
  display: flex;
  flex: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.confirmation-popup-lite-button-container h3 {
  color: black;
}

.confirmation-popup-lite-button-container button {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.confirmation-popup-lite-button-container button:hover {
  opacity: 75%;
  cursor: pointer;
}

.confirmation-popup-button-container .confirmation-popup-cancel {
  background-color: var(--rejectRed);
}

.confirmation-popup-lite-confirm {
  background-color: var(--confirmGreen);
}
