:root {
  --themeColor: #d8262e;
  --themeDarkColor: #b91e24;
  --themeLightColor: #fff5f5;

  --athleticDarkestOrange: rgb(217, 79, 3);
  --athleticDarkOrange: rgb(255, 156, 97);
  --athleticOrange: rgb(250, 181, 140);
  --athleticYellowOrange: rgb(230, 148, 46);

  --clubBlue: rgb(189, 204, 255);
  --clubDarkBlue: rgb(102, 128, 255);
  --clubDarkestBlue: rgb(0, 31, 138);

  --othersDarkestGray: rgb(133, 133, 133);
  --othersDarkGray: rgb(230, 230, 230);
  --othersGray: rgb(237, 237, 237);

  --renterDarkestPurple: rgb(117, 0, 133);
  --renterDarkPurple: rgb(235, 97, 255);
  --renterPurple: rgb(242, 184, 255);

  --buttonDarkestGreen: rgb(2, 113, 0);
  --buttonDarkGreen: rgb(5, 209, 0);
  --buttonDarkRed: rgb(209, 5, 0);
  --buttonGreen: rgb(171, 255, 171);
  --buttonRed: rgb(255, 171, 171);

  --cardGray: rgb(247, 247, 247);
  --selectDarkGreen: rgb(26, 153, 20);
  --selectGreen: rgb(235, 255, 227);

  --confirmGreen: rgb(242, 255, 235);
  --editBlue: rgb(222, 222, 255);
  --pendingYellow: rgb(255, 242, 222);
  --rejectRed: rgb(255, 222, 222);
}
