.login-page {
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-page .login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
  padding: 80px;
}

.login-page .login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.login-page .login-header img {
  width: 100px;
  height: 100px;
}

.login-page .login-header h2 {
  color: #333;
  margin: 0;
}

.login-page .auth-info {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-page .auth-info input[type="email"],
.login-page .auth-info input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  padding-right: 35px;
}

.login-page .remember-me {
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.login-page .remember-me input:hover {
  cursor: pointer;
}

.login-page .login-content p {
  color: red;
  text-align: center;
  margin: 0;
}

.login-page .password {
  position: relative;
  align-items: center;
}

.login-page .password button {
  all: unset;
  cursor: pointer;
}

.login-page .password i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  text-align: center;
}

.login-page .login-button {
  all: unset;
  cursor: pointer;
  background-color: var(--themeColor);
  width: 400px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-top: 20px;
}

.login-page .login-button h3 {
  color: white;
  font-size: 16px;
  margin: 0;
}
