.landing-home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.landing-home .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 50px;
}

.landing-home .container img {
  width: 432px;
  height: 768px;
}

.landing-home .auth-buttons {
  display: flex;
  gap: 10px;
}
