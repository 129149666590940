.materials-chip {
  border: 1px solid var(--themeColor);
  border-radius: 12px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  color: var(--themeColor);
}

.materials-chip .materials-chip-increment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.materials-chip button {
  all: unset;
  cursor: pointer;
}
