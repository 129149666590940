.notification {
  width: 90%;
  padding: 30px 20px 20px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  text-align: left;
  margin-bottom: 5%;
}

.notification button {
  all: unset;
  width: 100%;
  cursor: pointer;
  text-align: left;
  background: none;
  color: inherit;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification h4 {
  color: var(--themeColor);
  margin: 5px 0;
}

.notification-header h3,
.notification-header h4 {
  color: black;
  margin: 0;
}
