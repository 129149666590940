.calendar-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.calendar-page .calendar-container {
  width: 70vw;
  height: 95vh;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.calendar-page .calendar-header {
  border: 2px solid #f0f0f0;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.calendar-page .calendar-header h3 {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 1;
}

.calendar-page .calendar-header button {
  all: unset;
  cursor: pointer;
  color: var(--themeColor);
}

.calendar-page .calendar-box {
  border: 2px solid #f0f0f0;
  border-radius: 20px;
  padding: 20px;
  height: 100%;
}

.fc {
  width: 100%;
  height: 100%;
}

.fc-toolbar-title {
  font-weight: bold;
  color: var(--themeColor);
}

.fc-col-header,
.fc-col-header-cell.fc-day-sat,
.fc-col-header-cell.fc-day-sun,
.fc-col-header-cell.fc-day-today {
  background-color: var(--themeColor) !important;
}

.fc-col-header a,
.fc-col-header-cell.fc-day-sat a,
.fc-col-header-cell.fc-day-sun a {
  color: white;
}

/* Style for the Day Cells */
.fc-daygrid-day {
  border: 1px solid #4d4a4a;
}

.fc-daygrid-day-number {
  color: black;
}

/* Style for Events in Month View */
.fc-daygrid-event {
  color: white;
  border-radius: 4px;
  padding: 2px 12px;
}

.fc-daygrid-event-dot {
  display: none !important;
}

.fc-daygrid-event .fc-event-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
}

.fc-daygrid-event.Athletics {
  background-color: var(--athleticDarkOrange);
}

.fc-daygrid-event:hover.Athletics {
  color: var(--athleticDarkestOrange);
}

.fc-daygrid-event.Clubs {
  background-color: var(--clubDarkBlue);
}

.fc-daygrid-event:hover.Clubs {
  color: var(--clubDarkestBlue);
}

.fc-daygrid-event.Renters {
  background-color: var(--renterDarkPurple);
}

.fc-daygrid-event:hover.Renters {
  color: var(--renterDarkestPurple);
}

.fc-daygrid-event.Others {
  background-color: var(--othersDarkestGray);
}

.fc-daygrid-event:hover.Others {
  color: black;
}

.fc-daygrid-event.Existing {
  background-color: var(--othersDarkestGray);
}

.fc-daygrid-event:hover.Existing {
  color: black;
}

/* Style for Events in Time Grid (Week/Day View) */
.fc-timegrid-event {
  color: white;
  border-radius: 4px;
  padding: 2px 4px;
}

.fc-timegrid-event.Athletics {
  background-color: var(--athleticDarkOrange);
}

.fc-timegrid-event.Clubs {
  background-color: var(--clubDarkBlue);
}

.fc-timegrid-event.Renters {
  background-color: var(--renterDarkPurple);
}

.fc-timegrid-event.Others {
  background-color: var(--othersDarkestGray);
}

/* Highlight Today's Date */
.fc-day-today {
  background: var(--themeLightColor) !important;
}

/* Style for Weekend Days */
.fc-day-sat,
.fc-day-sun {
  background-color: #f7f7f7;
}

/* Style for Tooltips or Event Popups (if using custom tooltip library) */
.fc-event:hover {
  cursor: pointer;
  opacity: 0.9;
}

.fc-prev-button,
.fc-next-button,
.fc-today-button,
.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button {
  background-color: var(--themeColor) !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
}

.fc-prev-button:hover,
.fc-next-button:hover,
.fc-today-button:hover,
.fc-dayGridMonth-button:hover,
.fc-timeGridWeek-button:hover,
.fc-timeGridDay-button:hover {
  background-color: var(--themeDarkColor) !important;
}

.calendar-page .search-column {
  padding: 10px;
  flex-grow: 1;
  border-radius: 20px;
  border: 2px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20vw;
  overflow-y: scroll;
}

.calendar-page .events-search {
  position: relative;
  width: 90%;
}

.calendar-page .events-search input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  padding-right: 35px;
}

.calendar-page .events-search i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.calendar-page .filtered-events-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.calendar-page .filtered-events {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 0;
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
  align-items: center;
}

.calendar-page .filtered-event-name {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}

.calendar-page .filtered-event-date {
  text-align: right;
}
