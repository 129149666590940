.demos img {
  width: 360px;
  height: 640px;
  object-fit: cover;
}

.demos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 200px;
  background-color: white;
}

.button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}

.button:hover {
  opacity: 0.8;
}
